html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

#root,
.webp,
.nowebp {
  height: 100%;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 1rem;
  margin: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

.MuiBackdrop-root {
  background-color: rgba(20, 23, 86, 0.9) !important;
}

ol.MuiBreadcrumbs-ol {
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #818181;
  font-size: 12px;
  margin-bottom: 10px;
}

.MuiBreadcrumbs-ol a {
  color: inherit;
  text-decoration: none;
}

li.MuiBreadcrumbs-separator {
  margin-left: 4px;
  margin-right: 4px;
}
